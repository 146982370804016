


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --mainColor: #ff9138;
}
.btn{
  cursor: pointer;
}
.btn:hover{
  color: var(--mainColor);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f3d56; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f3d56; 
}
.gradient {
  background: var(--mainColor)
}

.black-text {
  color: black !important;
}

[margin=auto] {
  margin: auto !important;
}

.h-170 {
  max-height: 170px !important;
  min-height: 170px !important;
}

.offers-img {
  max-height: 166px !important;
  margin-top: 24px !important;
}

td {
  position: relative;
}
.mobile-space-contact{
  margin-bottom: 1rem !important;
  height: 70px !important;
  }
@media only screen and (max-width: 767px) {
  .mobile-space-contact{
      margin-bottom: 1rem !important;
      height: 60px !important;
      }
}

.bullet-facelabs {
  color: var(--mainColor);
  position: absolute;
  left: -20px;
  top: -10px;
  font-size: 1.8rem;
  vertical-align: top !important;
}

@media (min-width: 1440px) {
  .container--facelabs {
      max-width: 1440px;
  }
}




