
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a[href]{
  color:rgba(0, 0, 255, 0.911);
  }
  a[href]:hover{
  text-decoration: underline;
  }
  .bold{
      font-weight: 700;
  }
  ul.bullets {
      list-style: circle !important;
      margin: 1rem !important;
      padding: 0 !important;
      font-size: 15px !important;
  }
  [align='center']{
      text-align: center !important;
  }
  p{
      color: #000000b8;
  }
  .fade-in{
    animation: pulsate 1s ease;

  }

  @-webkit-keyframes pulsate {
    from{
      opacity: 0;
     }
     to{
      opacity:1;
     }
}
@-moz-keyframes pulsate {
   from{
    opacity: 0.3;
   }
   to{
    opacity:1;
   }
}
@-ms-keyframes pulsate {
  from{
    opacity: 0.3;
   }
   to{
    opacity:1;
   }
}
@-o-keyframes pulsate {
  from{
    opacity: 0.3;
   }
   to{
    opacity:1;
   }
}
@keyframes pulsate {
  from{
    opacity: 0.3;
   }
   to{
    opacity:1;
   }
}

  .flashing{
      animation: flashing 3s infinite;
  
  }
  @keyframes flashing {
      0% {
          box-shadow: none;
      }
  
      5% {
          box-shadow: none;
      }
  
      50% {
          box-shadow: 0px 1px 2px 5px #f6ae5593;
      }
  
      95% {
          box-shadow: none;
      }
  
      100% {
          box-shadow: none;
      }
  }
  
  @-moz-keyframes flashing {
  }
  
  @-webkit-keyframes flashing {
  }
  
 