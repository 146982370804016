.Cover{
    position: fixed;
    top: 0;
    left:0;
    background: #ffffffd6;
    width: 100vw;
    height:100vh;
    z-index: 1100;
    animation: fadeIn .5s ease-in-out;
}

  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.Text{
    font-size: 1.3rem;
    color:rgba(0, 0, 0, 0.37);
    min-width: 200px;
    padding-top:30px;
    transform: translateX(-34%);
    font-weight: 700;
}
.Loader:before{
	content: "";
	position: absolute;
	top: 0px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
	-webkit-animation: Loaderg 3s ease-in-out infinite;
			animation: Loaderg 3s ease-in-out infinite;
}

.Loader{
	position: relative;
	width: 12px;
	height: 12px;
	top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	border-radius: 12px;
	background-color: rgb(223, 150, 16);
}


.Loader:after{
	content: "";
	position: absolute;
	top: 0px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
	-webkit-animation: Loaderd 3s ease-in-out infinite;
			animation: Loaderd 3s ease-in-out infinite;
}

@-webkit-keyframes Loaderg{
	0%{	left: -25px; background-color: rgba(223, 151, 16, 0.719); }
	50%{ left: 0px; background-color:  rgba(223, 151, 16, 0.719); }
	100%{ left:-25px; background-color:  rgba(223, 151, 16, 0.719); }
}
@keyframes Loaderg{
	0%{	left: -25px; background-color: rgba(223, 151, 16, 0.719); }
	50%{ left: 0px; background-color: rgba(223, 151, 16, 0.719);}
	100%{ left:-25px; background-color: rgba(223, 151, 16, 0.719);}
}


@-webkit-keyframes Loaderd{
	0%{	left: 25px; background-color: rgba(223, 151, 16, 0.719); }
	50%{ left: 0px; background-color: rgba(223, 151, 16, 0.719);}
	100%{ left:25px; background-color: rgba(223, 151, 16, 0.719); }
}
@keyframes Loaderd{
	0%{	left: 25px; background-color: rgba(223, 151, 16, 0.719);}
	50%{ left: 0px; background-color: rgba(223, 151, 16, 0.719);}
	100%{ left:25px; background-color: rgba(223, 151, 16, 0.719); }
}